@use "../../styles/colors.scss";
@import "../../styles/mixins";

.navigation__hamburger-menu {
  display: none;

  @include respond(phone) {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-flow: column nowrap;
    height: 1.5rem;
  }

  .burger {
    width: 2.5rem;
    height: 0.25rem;
    background-color: colors.$link-default;
    z-index: 3;
  }
}
