$link-default: #383226;

$p-color-gold: #9b8c69;
$p-color-purple: #524b61;
$p-color-purple-hover: #6a627c;
$p-color-whitish: #fffefa;

$s-color-gold: #383226;
$s-color-gold-bg: #9b8b69;
$s-color-white: white;
$s-color-grey: #aeaeae;
$s-color-dark-grey: #939393;
$s-color-yellow: #fff9e3;
$s-color-yellow-hover: #ece6cf;

$footer-bg: #5d543d;
$footer-line: #786e53;

$bttn-gold-bg: $s-color-gold-bg;
$bttn-gold-bg-hover: $footer-line;
