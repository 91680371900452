@import "../../styles/colors.scss";
@import "../../styles/bgImages.scss";
@import "../../styles/varia.scss";
@import "../../styles/mixins";
@import "../../styles/typography";

div.detail-page {
    display: flex;

    @include respond(phone) {
        display: block;
    }

    a.active {
        position: relative;
        margin-right: 10rem;

        @include respond(phone) {
            margin-right: 0;
        }

        img.detail-go-back {
            height: 2.3rem;
            margin-top: 2.5rem;
            position: absolute;
            left: 0;
            transition: left 0.2s ease;
    
            @include respond(phone) {
                margin-top: 0;
            }

            &:hover {
                left: 2rem;

                @include respond(phone) {
                    left: 0;
                }
            }
        }
    }


    div.detail-heading {
        @include respond(phone) {
            padding-top: 4rem;
        }

        h2.job {
            color: $s-color-gold;
            margin-bottom: 1.1rem;
            width: 70%;
            position: relative;

            @include respond(phone) {
                width: auto;
                @include split-words;
            }
        
            &::after {
                content: none;
            }
        }
        h3.client {
            font-size: 3.2rem;
            margin-bottom: 8.2rem;
        }

        div.detailed-info-container {
            display: flex;
            align-items: center;
            margin-top: 2.2rem;

            @include respond(phone) {
                margin-top: 4rem;
                flex-flow: column;
                height: 6rem;
                justify-content: space-between;
                align-items: unset;
            }

            p.detailed-info-text {
                &.location-icon::before, &.calendar-icon::before {
                    @include bg-image;
                    display: inline-block;
                    margin-right: 1rem;
                    height: 1.7rem;
                    vertical-align: middle;
                  }
                
                  &.location-icon::before {
                    background-image: $location-icon;
                    width: 1rem;
                  }
                
                  &.calendar-icon::before {
                    background-image: $calendar-icon;
                    width: 1.3rem;
                  }
            }

            & p:first-child::after {
                content: " ";
                background-color: $s-color-dark-grey;
                border-radius: 50%;
                width: 0.7rem;
                height: 0.7rem;
                display: inline-block;
                margin: 0 2rem;
                vertical-align: middle;

                @include respond(phone) {
                    content: unset;
                }
            }
        }

        margin-bottom: 9.4rem;
        width: 100%;

        @include respond(phone) {
            margin-bottom: 4rem;
        }
    }
}

div.description-container {
    background: linear-gradient(0deg, rgba(255,254,250,1) 0%, rgba(255,246,234,1) 100%);

    div.description {
        width: 50%;
        margin: 0 auto;

        @include respond(phone) {
            width: auto;
            margin: 0 1.6rem;
        }
        section {
            padding: 9.4rem 10rem 0;

            p {
                margin-bottom: 2.4rem;
            }

            ul {
                list-style-type: disc; 
                margin-left: 5rem; 
                margin-bottom: 2.4rem;

                li {
                    display: list-item;
                    font-size: 2rem;
                    p {
                        margin-bottom: 0;
                    }
                }
            }

            &:first-child {
                padding-bottom: 8.4rem;
                border-bottom: $border-bottom;

                @include respond(phone) {
                    padding-bottom: 6rem;
                }
            }

            &:last-child {
                padding-top: 6.3rem;
                padding-bottom: 8.4rem;
                border-bottom: $border-bottom;

                > p.small-info-text {
                    margin-top: 1.8rem;
                }
            }

            @include respond(phone) {
                padding: 6rem 0;
            }
        }
    }
}

div.detail-page-share {
    width: 40vw;
    margin: 0 auto;
    margin-top: 9.4rem;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include respond(phone) {
        width: auto;
        display: block;
        margin: 0 1.6rem;
        margin-top: 6rem;
    }

    div.share-info {
        &:last-child {
            @include respond(phone) {
                margin-top: 4rem;
            }
        }
    }
}