@import "./styles/ratios";
@import "./styles/colors";
@import "./styles/mixins";

.App {
  width: inherit;
  height: inherit;
  background: radial-gradient(
    circle,
    rgba(254, 253, 251, 1) 0%,
    rgba(251, 248, 251, 1) 100%
  );
  > h1 {
    display: none;
  }

  .App-header {
    height: $header-height;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-bottom: $header-margin;
  }
}

.App-bg-white {
  background: $p-color-whitish;
}

.home-page-app {
  @include respond(phone) {
    height: 100vh;
  }
}

.hamburger-display {
  @include respond(phone) {
    overflow: hidden;
    height: 100vh;
  }
}
