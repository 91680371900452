@import "../../styles/colors";
@import "../../styles/ratios";
@import "../../styles/typography";
@import "../../styles/mixins";

div.privacy-policy-page {
  @include respond(phone){
    padding: 0 1.6rem;
  }

  section.privacy-policy-section {
    padding: 0 $padding-left;

    @include respond(phone) {
      padding: 0;
    }

    p {
        margin-bottom: 2.4rem;
    }

    ul {
        list-style-type: disc; 
        margin-left: 5rem; 
        margin-bottom: 2.4rem;

        li {
            display: list-item;
            font-size: 2rem;
            p {
                margin-bottom: 0;
            }
        }
    }
  }
}