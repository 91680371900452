@import "../../styles/colors";
@import "../../styles/ratios";
@import "../../styles/mixins";

footer {
  background-color: $footer-bg;
  margin-top: 20rem;

  @include respond(phone) {
    margin-top: 10rem;
  }

  > h2 {
    display: none;
  }

  div.footer__container {
    width: $header-width;
    margin: 0 auto;

    div.footer__links {
      display: flex;
      padding: 7rem 0 5rem;

      @include respond (phone) {
        flex-flow: column;
        align-items: center;
        text-align: center;
      }

      > div {
        display: flex;
        flex-flow: column;
        margin-right: 10rem;

        @include respond(phone) {
          align-items: center;
          margin-right: 0;

          margin-top: 6rem;

          &:first-child {
            margin-top: 0;
          }
        }

        &:last-child {
          margin-right: 0;
        }
        

        > h3 {
          font-family: "Libre Baskerville", serif;
          color: white;
          font-weight: bold;
          font-size: 2.8rem;
          margin-bottom: 1.7rem;
        }

        > a,
        p {
          color: white;
          font-size: 1.6rem;
          line-height: 3.55rem;
          margin-bottom: 0.1rem;
          opacity: 0.6;
        }

        > a:hover {
          opacity: 1;
        }
      }
    }

    div.footer__line {
      background-color: $footer-line;
      width: 100%;
      height: 0.1rem;
    }

    div.footer__sublinks {
      display: flex;
      align-items: center;
      padding: 5rem 0;

      @include respond (phone) {
        flex-flow: column;
        height: 24rem;
        justify-content: space-between;
      }

      > a,
      img,
      p {
        margin-right: 2rem;
        font-size: 1.4rem;
        font-weight: 500;
        color: white;
      }

      p:last-child {
        margin-right: 0;
      }
    }
  }
}
