@import "./colors";
@import "./bgImages";
@import "./mixins";

@mixin split-words {
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens:auto;
}

h1, .h1-title {
  font-family: "Libre Baskerville", serif;
  font-weight: bold;
  font-size: 6rem;
  line-height: 8.4rem;
  color: $p-color-gold;
  position: relative;
  margin-bottom: 3.8rem;

  @include respond(phone) {
    font-size: 6vh;
    line-height: 9vh;
  }

  &:after {
    content: " ";
    width: 4rem;
    height: 0.5rem;
    background-color: $p-color-gold;
    position: absolute;
    display: block;
  }
}

h2 {
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 4.4rem;
  color: $s-color-gold;
  margin-bottom: 2.4rem;

  @include respond(phone) {
    font-size: 4vh;
    line-height: 5vh;
  }
}

h3 {
  font-size: 1.8rem;
  line-height: 3rem;
  font-weight: bold;
  color: $p-color-gold;

  &.detail-h3 {
    font-size: 2.8rem;
    font-weight: bold;
    color: $s-color-gold;
    margin-bottom: 3.5rem;
  }
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

button,
a.button, {
  border-radius: 0.15rem;
  text-decoration: none;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: $s-color-white;
  padding: 1.5rem 2.4rem;
  background-color: $p-color-purple;
  transition: all 0.2s ease;

  &:hover {
    background-color: $p-color-purple-hover;
  }

  &.bttn-gold-bg {
    background-color: $bttn-gold-bg;

    &:hover {
      background-color: $bttn-gold-bg-hover;
    }
  }
}

p {
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 3rem;
  color: $s-color-gold;
}

.detailed-info-text {
  font-size: 1.8rem;
  font-weight: bold;
  color: $s-color-dark-grey;
}

.small-info-text {
  font-size: 1.3rem;
  font-weight: 400;
  font-style: italic;
  line-height: 3rem;
  color: $p-color-gold;
}

.contact-detailed-text {
  font-size: 1.8rem;
  font-weight: 600;
  color: $s-color-gold;
}


