@use "../../styles/colors.scss";
@import "../../styles/mixins.scss";

$ka_multiply: 1.2;
$ka_width: calc(13.8rem * $ka_multiply);
$ka_height: calc(2.3rem * $ka_multiply);

nav {
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .list-item__group {
      display: flex;
      align-items: center;

      @include respond(phone) {
        display: none;

        &.hamburger-open {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-flow: column;
          background-color: colors.$s-color-gold-bg;

          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 2;

          li {
            margin-right: 0;
            margin-bottom: 6rem;

            a {
              font-size: 4.2rem;
            }
          }
        }
      }
    }

    li {
      list-style: none;
      text-decoration: none;
      margin-right: 6.4rem;

      &:last-child {
        margin-right: 0;
      }

      .list-item__NavLink {
        text-decoration: none;
        font-size: 1.8rem;
        color: colors.$link-default;
        font-weight: 700;
        padding: 1rem 0;
        border-bottom: 0.2rem solid rgba(56, 50, 38, 0);

        &:hover {
          border-bottom: 0.2rem solid rgba(56, 50, 38, 1);
        }
      }

      a img {
        width: $ka_width;
        height: $ka_height;
      }

      .list-item__NavLink-active {
        text-decoration: none;
        font-size: 1.8rem;
        color: colors.$link-default;
        font-weight: 700;
        padding: 1rem 0;
        border-bottom: 0.2rem solid rgba(56, 50, 38, 1);
      }
    }
  }
}
