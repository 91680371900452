@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");

@font-face {
  font-family: "URW Geometric";
  src: url("../assets/fonts/URWGeometric/URWGeometric-Regular.woff") format("woff");
  src: url("../assets/fonts/URWGeometric/URWGeometric-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "URW Geometric";
  src: url("../assets/fonts/URWGeometric/URWGeometric-Thin.woff") format("woff");
  src: url("../assets/fonts/URWGeometric/URWGeometric-Thin.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "URW Geometric";
  src: url("../assets/fonts/URWGeometric/URWGeometric-ThinItalic.woff") format("woff");
  src: url("../assets/fonts/URWGeometric/URWGeometric-ThinItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "URW Geometric";
  src: url("../assets/fonts/URWGeometric/URWGeometric-Medium.woff") format("woff");
  src: url("../assets/fonts/URWGeometric/URWGeometric-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "URW Geometric";
  src: url("../assets/fonts/URWGeometric/URWGeometric-MediumItalic.woff") format("woff");
  src: url("../assets/fonts/URWGeometric/URWGeometric-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "URW Geometric";
  src: url("../assets/fonts/URWGeometric/URWGeometric-Bold.woff") format("woff");
  src: url("../assets/fonts/URWGeometric/URWGeometric-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "URW Geometric";
  src: url("../assets/fonts/URWGeometric/URWGeometric-BoldItalic.woff") format("woff");
  src: url("../assets/fonts/URWGeometric/URWGeometric-BoldItalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}
