@import "./styles/_colors.scss";
@import "./styles/_fonts.scss";

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "URW Geometric";
  width: inherit;
  height: inherit;
}

ul,
li,
button,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p {
  padding: 0;
  margin: 0;
  text-decoration: none;
}

#root {
  width: inherit;
  height: inherit;
}

.header-width {
  width: 85%;
  margin: 0 auto;
}

@media only screen and (max-width: 48em) {
  .header-width {
    width: auto;
    margin: 0 1.6rem;
  }
}

