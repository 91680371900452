@mixin bg-image {
    content: " ";
    background-repeat: no-repeat;
    background-size: contain;
}

$detail-back: url("../assets/icons/detail-back.svg");
$location-icon: url("../assets/icons/location.svg");
$calendar-icon: url("../assets/icons/calendar.svg");
$contact-calendar: url("../assets/icons/contact_calendar.svg");
$contact-phone: url("../assets/icons/contact_phone.svg");