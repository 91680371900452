@import "../../styles/ratios";
@import "../../styles/mixins";

div.home-page {
  height: $home-page-height;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: inherit;

  background-image: url("../../assets/images/home_shape_1.svg");
  background-repeat: no-repeat;
  background-size: 8%;
  background-position: left bottom;

  @include respond(phone) {
    display: block;
    background-size: 25%;
  }

  div.home-page__content {
    max-width: 50rem;
    padding-left: $padding-left;

    @include respond(phone) {
      max-width: none;
      padding: 0;
      margin: 0 1.6rem;
    }

    div.home-page__button-container {
      margin-top: 6.8rem;
    }
  }

  img {
    max-width: 50vw;
    max-height: 95%;

    @include respond(phone) {
      display: none;
    }
  }
}
