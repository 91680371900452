@mixin respond($breakpoint) {
  @if $breakpoint == smallerphone {
    @media only screen and (max-width: 26.6em) {
      @content;
    } //425.6px
  }
  @if $breakpoint == phone {
    @media only screen and (max-width: 48em) {
      @content;
    } //768px
  }
  @if $breakpoint == tabportrait {
    @media only screen and (max-width: 62em) {
      @content;
    } //992px
  }
  @if $breakpoint == tabportraitPro {
    @media only screen and (max-width: 64em) {
      @content;
    } //1025px
  }
  @if $breakpoint == tablet {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == tabletPro {
    @media only screen and (max-width: 80em) {
      @content;
    } //1280px
  }
  @if $breakpoint == desktop {
    @media only screen and (max-width: 90em) {
      @content;
    } //1440px
  }
  @if $breakpoint == desktoplarge {
    @media only screen and (max-width: 105em) {
      @content;
    } //1680px
  }
  @if $breakpoint == desktopxlarge {
    @media only screen and (max-width: 120em) {
      @content;
    } //1920px
  }
  @if $breakpoint == desktopxxlarge {
    @media only screen and (max-width: 150em) {
      @content;
    } //2400px
  }
}

@mixin ieOnly() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

//Safari from v10.1
@mixin safariOnly() {
  @media not all and (min-resolution: 0.001dpcm) {
    @content;
  }
}

@mixin chromeOnly() {
  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    @content;
  }
}
