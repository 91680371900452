@import "../../styles/ratios";
@import "../../styles/varia";
@import "../../styles/bgImages";
@import "../../styles/mixins";

div.contact-page {
    height: $home-page-height;
    margin-left: $padding-left;
    margin-right: 12rem;
    display: flex;
    width: -webkit-fill-available;
    overflow: hidden;

    @include respond(desktop) {
        display: block;
        height: auto;
    }

    @include respond(phone){
        padding: 0 1.6rem;
        margin: 0;
        height: auto;
    }

    div.contact__content {
        max-width: 60rem;
        margin-right: 15rem;
        height: fit-content;

        @include respond(phone){
            margin-right: 0;
            max-width: none;
        }

        div.content__header {
            padding-bottom: 6rem;
            border-bottom: $border-bottom;

            @include respond(phone) {
                padding-bottom: 4rem;
            }

            > p {
                margin-bottom: 3.8rem;
            }
        }

        div.content__addit {
            display: flex;
            justify-content: space-between;
            width: 60rem;
            margin-top: 4.7rem;

            @include respond(phone) {
                margin-top: 4rem;
                width: auto;
                display: block;
                justify-content: unset;
            }

            div.contact-detail-ic {
                display: inherit;
                align-items: baseline;

                @include respond(phone) {
                    margin-top: 4rem;
                }

                &::before {
                    @include bg-image;
                    display: inline-block;
                    margin-right: 1rem;
                    height: 1.7rem;
                    vertical-align: middle;

                }
            }

            .det-calendar-ic::before {
                background-image: $contact-calendar;
                width: 2.4rem;
            }

            .det-phone-ic::before {
                background-image: $contact-phone;
                width: 2rem;
            }
        }
    }

    img {
        //max-width: 55rem;
        max-height: 55rem;

        @include respond(desktop) {
            max-width: 90%;
            max-height: -webkit-fill-available;
            margin: 4rem 0;        
        }

        @include respond(phone) {
            max-width: -webkit-fill-available;
            max-height: -webkit-fill-available;
            margin: 4rem 0;
        }
    }

    & + footer {
        display: none;

        @include respond(phone) {
            display: block;
            margin-top: 4rem;
        }
    }
}

