@import "../../styles/colors";
@import "../../styles/ratios";
@import "../../styles/typography";
@import "../../styles/mixins";

div.vacatures-page {
  @include respond(phone){
    padding: 0 1.6rem;
  }

  section.section__vacatures {
    padding-left: $padding-left;

    @include respond(phone) {
      padding: 0;
    }
  }

  h1 {
    margin-bottom: 9rem;
    @include split-words;
  }
  p.vacatures-page__results {
    font-weight: bold;
    color: $s-color-grey;
    margin-bottom: 2.2rem;
  }

  ul {
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    li {
      padding: 2.3rem 4rem;
      background-color: #fffefa;
      border: 2px solid #cdc8d0;
      display: inline-block;
      margin-bottom: 2rem;
      transition: all 0.2s ease;
      width: 50rem;

      @include respond(phone) {
        padding: 2rem;
        width: -webkit-fill-available;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background-color: #f5f3eb;
        margin-left: 2rem;

        @include respond(phone) {
          margin-left: 0;
        }
      }
      a {
        h2 {
          margin: 0;
        }

        div.vacatures-page__details {
          display: flex;
          align-items: center;

          @include respond(phone) {
            display: block;
            width: auto;
            margin-top: 1.6rem;
          }

          p {
            font-size: 1.4rem;
            font-weight: bold;
            color: $s-color-dark-grey;
            margin-right: 2rem;

            &.vacatures-page__place::before,
            &.vacatures-page__calendar::before {
              content: " ";
              background-repeat: no-repeat;
              width: 1.2rem;
              height: 1.5rem;
              margin-right: 0.5rem;
              display: inline-block;
              vertical-align: middle;
            }

            &.vacatures-page__place::before {
              background-image: url("../../assets/icons/location.svg");
            }

            &.vacatures-page__calendar::before {
              background-image: url("../../assets/icons/calendar.svg");
            }

            &:last-child {
              margin-right: 0;
            }
          }

          div.grey-circle {
            width: 0.4rem;
            height: 0.4rem;
            border-radius: 50%;
            background-color: $s-color-dark-grey;
            margin-right: 2rem;

            @include respond(phone){
              display: none;
            }
          }
        }
      }
    }
  }

  section.section__more-info {
    width: $header-width;
    margin: 0 auto;
    background-color: $s-color-gold-bg;
    margin-top: 30rem;
    border-radius: $borderRadius;

    @include respond(phone) {
      width: auto;
      padding: 0 1.6rem;
      margin-top: 10rem;
    }

    div {
      padding: 6rem 9.4rem;
      width: 50%;

      @include respond(phone){
        padding: 6rem 1.6rem;
        width: auto;
      }

      h2,
      p {
        color: $p-color-whitish;
      }

      p {
        line-height: 4rem;
      }

      button {
        margin-top: 5.6rem;
        background-color: $s-color-yellow;
        color: $s-color-gold;
        transition: all 0.2s ease;

        &:hover {
          background-color: $s-color-yellow-hover;
        }
      }
    }
  }
}
